import { SERVER } from "./../settings";

import axios from "axios";

axios.defaults.withCredentials = true;

export const get = async (url: string) =>
{
    console.log(`AXIOS : getting <${url}>`);

    try {
    const response = await axios.request({
      url: `${SERVER}${url}`,
      method: "get",
      responseType: "json",
    });
    return response;
  } catch (error) {
    return error;
  }
   
}

export const post = (url: string, data: any) =>
{
  return new Promise((resolve, reject) =>
  {
    console.log(`AXIOS : posting / ${url}`, data);
    
    axios.request({
      url: `${SERVER}${url}`,
      method: "post",
      responseType: "json",
      data,
      // withCredentials: true
    }).then((response) =>
    {
      resolve(response);
    }).catch((error) => 
    {
      // Promise.reject(error.response);
      reject(error);
      // resolve(error);
    });
  });
}

export const put = (url: string, data: any) =>
{
  return new Promise((resolve, reject) =>
  {
    console.log(`AXIOS putting ${url}`, data);
    
    axios.request({
      url: `${SERVER}${url}`,
      method: "put",
      responseType: "json",
      data,
      // withCredentials: true
    }).then((response) =>
    {
      resolve(response);
    }).catch((error) => 
    {
      // Promise.reject(error.response);
      reject(error);
      // resolve(error);
    });
  });
}