import React from 'react';

import './App.css';
import './buttons.css';

import Typography from '@material-ui/core/Typography';
import { Container, ThemeProvider, withStyles } from '@material-ui/core';

import Box from '@material-ui/core/Box';

import styles from "./styles";
// import classNames from 'classNames';
import myTheme from "./Theme";
import { get } from './lib/api';


interface IAppProps {
  classes: any;
}

class AppState
{
  public message: string = "";
}

class App extends React.Component<IAppProps, AppState>
{
  private count: number = 0;

  constructor (props: any)
  {
    super(props);
    this.state = new AppState();

    
  }

  public componentDidMount = async () =>
  {
    this.testMe();
  }

  public testMe()
  {
    get("/").then((response: any) =>
    {
      console.log(">>", this.count, response);
      this.count++;
      this.setState({message: response.data.status});
    });
  }

  public render = () => 
  {
    const { classes } = this.props;
    const {message} = this.state;

    return (
      <ThemeProvider theme={myTheme}>
        <div className="App">
          <Container
            maxWidth={false}
            className={"bannerTop"}
            style={
              {
                padding: 0,
                overflow: "hidden",
              }
            }
          >
          </Container>

          <Box display="flex" flexDirection="column" p={1} bgcolor="background.paper" justifyContent="center" alignItems="center"
          style={{position: "relative"}}
          className = {"banner"}
          >
           
            <Typography variant="h4">Cartes <span className="suit black">♠</span> <span className="suit red">♥</span> <span className="suit black">♣</span> <span className="suit red">♦</span></Typography>
            {/* <Typography variant="body1">We're working on it!</Typography> */}
            <Typography variant="body1">{message}</Typography>
          </Box>

          <Container style={{ "marginTop": "1rem" }}>
            {/* <Box display="flex" flexDirection="row" p={1} bgcolor="background.paper" justifyContent="center" alignItems="center"> */}

           

            
          </Container>
        </div>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles, { withTheme: true })(App);
